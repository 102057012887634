body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.object-cover {
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  width: 70%;
  height: auto;
}

.object-cover-fix {
  margin: 13px 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.btn-custom{
  border: 1px solid red;
  color: red;
  font-size: 20px;
  margin: 20px 0px;
}
.row {
  margin: 15px;
}
.container{
  padding: 0px;
  margin: auto;
}
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: visible;
  /* Enable scroll if needed */
/* From https://css.glass */
background: rgba(245, 239, 239, 0.3);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(12.1px);
-webkit-backdrop-filter: blur(12.1px);
  
  
}


/* Modal Content */
.modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  overflow: visible;
  width: 50%;
  height: 500px;
  z-index: 20;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-img {
  position: absolute;
  z-index: 1;
  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .modal-content {
    width: 100%;
  }
}

.btn-thamdu {
  display: inline-block;
  background: transparent;
  text-transform: uppercase;
  font-weight: 900;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.3em;
  color: white;
  border-radius: 0;
  padding: 15px 60px 15px;
  transition: all 0.7s ease-out;
  background-color: rgb(185 28 28/var(--tw-bg-opacity));
  text-decoration: none;
  margin: 0.625rem;
  margin-top: 40px;
}

.btn-thamdu:hover {
  color: #fff;
  border: 1px solid rgba(223,190,106,0);
  color: white;
  background-color: rgb(239 68 68/var(--tw-bg-opacity));;
}